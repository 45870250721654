<template>
  <div class="relative">
    <Modal v-model="infoModal" title="Details">
      <h2>{{ info.title }}</h2>
      <h3>{{ info.meeting_company }}</h3>
      <div class="mb-10">
        {{ info.local_start_time }} - {{ info.local_end_time }}
      </div>
      <div class="mb-20">{{ info.description }}</div>
      <div class="users">
        <div class="user" :key="user.id" v-for="user in info.users">
          <Tooltip
            :content="`${user.first_name} ${user.last_name}`"
            placement="top"
          >
            <Avatar :src="user.avatar.url" />
          </Tooltip>
        </div>
      </div>
    </Modal>

    <Spin v-if="loading" fix></Spin>
    <router-link to="/home" class="back">
      <Icon type="ios-arrow-back" />
      <span>Return to Home</span>
    </router-link>

    <div class="box">
      <div class="right">
        <FullCalendar :options="calendarOptions" @eventClick="eventClick" />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
const { _queryMyAgenda } = api;

import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { mapState } from "vuex";
import moment from "moment";

export default {
  computed: {
    ...mapState("user", ["company_id"])
  },
  components: {
    FullCalendar
  },
  data() {
    return {
      info: {},
      infoModal: false,
      loading: false,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin // needed for dateClick
        ],
        headerToolbar: {
          left: "dayGridMonth,timeGridWeek,timeGridDay",
          center: "title",
          right: "prev,next today"
        },
        initialView: "dayGridMonth",
        events: this.queryCalendar,
        eventClick: this.eventClick
      }
    };
  },
  methods: {
    eventClick(e) {
      this.info = JSON.parse(JSON.stringify(e.event.extendedProps));
      this.infoModal = true;
    },
    queryCalendar(e, successCallback) {
      this.loading = true;
      _queryMyAgenda({
        start_time: moment(e.start)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        end_time: moment(e.end)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss")
      })
        .then(response => {
          const { data } = response;
          successCallback(
            data.map(item => {
              let company_name = "";
              if (String(this.company_id) === String(item.meeting_company_id)) {
                company_name = item.company.company_name;
              } else {
                company_name = item.meeting_company.company_name;
              }
              return {
                title: `${item.title}-${company_name}`,
                start: moment.utc(item.start_time).format(),
                end: moment.utc(item.end_time).format(),
                // more info
                extendedProps: {
                  title: item.title,
                  meeting_company: company_name,
                  description: item.description,
                  local_start_time: moment
                    .utc(item.start_time)
                    .local()
                    .format("HH:mm"),
                  local_end_time: moment
                    .utc(item.end_time)
                    .local()
                    .format("HH:mm"),
                  users: item.users
                }
              };
            })
          );
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.relative {
  position: relative;
  min-height: 300px;
}

.back {
  color: @primary-color;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 14px 24px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  background: #fff;
}

.box {
  display: flex;
  .left {
    width: 300px;
    border-right: 1px solid #ccc;
  }
  .right {
    flex: 1;
    padding: 20px;
  }
}

.users {
  display: flex;
  .user {
    margin-right: 10px;
  }
}
</style>
